<template>
  <div>
    <label class="flex-align lp-number-unit" :style="wrapperStyle">
      <input class="input"
             style="text-align:right"
             ref="input"
             type="number"
             :value="inputValue"
             min="0"
             :maxlength="maxLength"
             @keyup.enter="$emit('onEnter')"
             :placeholder="placeholder"
             @input="e => onKeyup(e.target.value)"
             :disabled="disabled"/>
      <span class="body3 number-unit" v-if="unit">{{ unit }}</span>
    </label>
  </div>
</template>
<script>
  import FormMixin from "@/mixins/FormMixin";
  export default {
    name: "LpNumberUnit",
    mixins: [
      FormMixin
    ],
    props: {
      value: {
        type: Number
      },
      disabled: {
        type: Boolean,
        required: false
      },
      maxLength: {
        type: Number,
        required: 100
      },
      placeholder: {
        type: String,
        default: ''
      },
      unit: {
        type: String,
        required: true
      },
      width: {
        type: String
      },
      validate: {
        type: Object,
        default: function() {
          return {
            valid_required: false,
            error_msg: '',
            max_length: 100,
            min_length: 0,
            alpha_s: true,
            alpha_g: true,
            special: true,
            num: true
          }
        }
      }
    },
    watch: {
      value(n) {
        this.inputValue = n;
      }
    },
    created() {
      if(this.value) {
        this.inputValue = this.value;
        if(this.type === 'number') {
          if(typeof(this.inputValue) === String) {
            this.inputValue = this.inputValue.replace(/-/gi, '');
          }
        }
      }
      if(this.validate) {
        this.valid = this.validate;
      }
    },
    data() {
      return {
        inputValue: 0,
        errorMsg: ''
      }
    },
    computed: {
      wrapperStyle() {
        let style = {};
        if(this.width) {
          style.width = this.width;
        }
        return style;
      }
    },
    methods: {
      onKeyup(value) {
        this.inputValue = parseInt(value);
        this.$emit('update:value', this.inputValue);
      },
      chkValid() {
        if (!this.valid.valid_required) return;
        let props = {
          value: this.inputValue,
          type: this.type,
          validate: this.validate
        }
        this.errorMsg = this.isValid(props, this.name);
        this.validate.error_msg = this.errorMsg;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .lp-number-unit
    border 1px solid $gray1
    border-radius 8px
    height 48px
  .lp-number-unit > input
    border none !important
    box-shadow none !important
    padding-right 2px
    flex 1
  .number-unit
    padding-right 10px

  .lp-number-unit > input[type=number]::-webkit-inner-spin-button,
  .lp-number-unit > input[type=number]::-webkit-outer-spin-button
    -webkit-appearance none
</style>