<template>
  <div>
    <div class="banner">
      <div class="mo-section-title-bold sub">런치팩 소스 파일로 등록하고<br>수익창출 하세요!</div>
      <div class="body4 sub2 margin-top-8">예산을 들여 제작한 결과물을 활용하지 않고<br/>묵혀두고 계셨나요?<br/>예비창업팀에게 공유하고 수익창출 하세요.</div>
      <img src="/static/img/img_gold_2.png" alt="템플릿 등록 문의" style="width:260px;height:190px;margin-top:40px">
    </div>
    <div style="padding:0 16px">
      <div class="h8 main margin-top-24 margin-bottom-24">소스 파일 등록 문의</div>
      <div v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
        <div class="margin-top-24">
          <div class="inquiry-reg-title subtitle7 margin-bottom-8">
            <template v-if="form.field !== 'checkedVal'">
              {{ form.label }} {{ form.validate.required ? '*' : '' }}
            </template>
          </div>
          <div class="inquiry-reg-content">
            <!--<input-single-file :value.sync="form.file_ext"
                               class="margin-top-8"
                               @setValue="val=>form.value=val"
                               placeholder="예) 소개서, 기획안 와이어프레임 등등"
                               v-if="form.component_name==='input-single-file'"></input-single-file>-->
            <input-file v-if="form.component_name === 'input-file'"
                        :value.sync="form.value" :placeholder="form.placeholder"></input-file>
            <component :is="form.component_name"
                       :ref="form.field"
                       v-bind.sync="form" v-else></component>
          </div>
        </div>
      </div>
      <button class="button is-primary body2-medium" style="width:100%;height:52px;margin-top:24px"
              @click="saveData">등록하기</button>
    </div>
  </div>
</template>

<script>
  import LpCheckBool from "../../../module/LpCheckBool";
  import LpRadio from "../../../module/LpRadio";
  import InputSingleFile from "../../../module/InputSingleFile";
  import LpText from "../../../component/LpText";
  import LpTextArea from "../../../component/LpTextArea";
  import LpNumberUnit from "../../../component/LpNumberUnit";
  import UserAPIMixin from "../../../../mixins/UserAPIMixin";
  import FormMixin from "../../../../mixins/FormMixin";
  import LpCheckBoxArray from "../../../component/LpCheckBoxArray";
  import LpDropDown from "../../../LpDropDown";
  import InputFile from "../../../module/InputFile";
  import InputImgUploaderArray from "../../../module/InputImgUploaderArray";
  export default {
    name: "InquiryMobile",
    mixins: [
      UserAPIMixin,
      FormMixin
    ],
    components: {
      InputImgUploaderArray,
      InputFile,
      LpDropDown, LpCheckBoxArray, LpNumberUnit, LpTextArea, LpText, InputSingleFile, LpRadio, LpCheckBool},
    async created() {
      await this.getInquiryCategory();
      this.setForm();
    },
    data() {
      return {
        catArr: [],
        formData: []
      }
    },
    methods: {
      clickLogin() {
        this.$store.commit('setPrevPath', this.$route.fullPath);
        this.$router.push('/signin');
      },
      setForm() {
        let checkFontSize = '15px';
        let width = '270px';
        if(window.innerWidth<500) {
          checkFontSize = '12px';
          width = '100%';
        }
        this.formData = [
          {
            label: '소스 파일 카테고리',
            placeholder: '카테고리를 선택해주세요.',
            maxLength: 30,
            field: 'category_id',
            type: 'dropdown',
            value: '',
            component_name: 'lp-drop-down',
            errorMsg: '',
            options: this.catArr,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '제목',
            placeholder: '제목을 입력해주세요.',
            maxLength: 30,
            field: 'title',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '내용',
            placeholder: '내용을 입력해주세요.',
            maxLength: 10000,
            field: 'content',
            type: 'text',
            value: '',
            component_name: 'lp-text-area',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 10000,
              min_length: 1,
              valid_required: true,
              required: true
            },
          },
          {
            label: '서비스 url',
            placeholder: 'url',
            maxLength: 30,
            field: 'url',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 200,
              min_length: 1,
              valid_required: false,
              required: false
            }
          },
          {
            label: '소스 파일 이미지',
            placeholder: '',
            field: 'imgs',
            value: [],
            component_name: 'input-img-uploader-array',
            validate: {
              valid_required: false,
              required: false
            }
          },
          {
            label: '소스 파일',
            placeholder: '예) 소개서, 기획안 와이어프레임 등등',
            field: 'files',
            value: [],
            component_name: 'input-file',
            validate: {
              valid_required: true,
              required: true
            }
          },
          {
            label: '성함',
            placeholder: '성함을 입력해주세요.',
            maxLength: 30,
            field: 'name',
            type: 'text',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            width: width,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '연락처',
            placeholder: '연락처를 입력해주세요.',
            maxLength: 30,
            field: 'phone',
            type: 'phone',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            width: width,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '이메일',
            placeholder: '이메일을 입력해주세요.',
            maxLength: 30,
            field: 'email',
            type: 'email',
            value: '',
            component_name: 'lp-text',
            errorMsg: '',
            width: width,
            validate: {
              num: true,
              alpha_g: true,
              alpha_s: true,
              special: true,
              max_length: 30,
              min_length: 1,
              valid_required: true,
              required: true
            }
          },
          {
            label: '이용약관 및 개인정보처리방침에 동의하기',
            items: [
              {
                label: '이용약관 및 개인정보처리방침에 동의하기',
                labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#555'
                }
              },
              {
                label: '(필수)', labelStyle: {
                  fontSize: checkFontSize,
                  lineHeight: '15px',
                  color: '#FF6600'
                }
              },
              {
                label: '약관보기', link: 'privacy',
                labelStyle: {
                  color: '#828282',
                  fontSize: checkFontSize,
                  textDecoration: 'underline',
                  marginLeft: '8px'
                }
              }
            ],
            field: 'checkedVal',
            type: 'check',
            value: false,
            component_name: 'lp-check-box-array',
            errorMsg: '',
            validate: {
              valid_required: true,
              required: true
            },
          }
        ];
        let name = '';
        let email = '';
        let phone = '';
        if (this.user.user_id) {
          let user = this.cloneItem(this.$store.getters.user)
          name = user.name;
          email = user.email;
          phone = user.phone;
          this.formData.forEach(item=>{
            if(item.field==='name') {
              item.value = name;
            } else if(item.field==='email') {
              item.value = email;
            } else if(item.field==='phone') {
              item.value = phone;
            }
          })
        }
      },
      async getInquiryCategory() {
        await this.$axios.get(`public/inquiry/source/category`).then(res=>{
          this.catArr = res.data.map(i=> {
            return {
              value: i.id,
              label: i.name
            }
          });
        })
      },
      saveData() {
        let result = this.getParamData(this.formData);
        if(result.enable) {
          let params = this.cloneItem(result.params);
          if (this.isLogin) {
            params.user_id = this.user.user_id;
          }
          let url = 'public/inquiry/source';
          this.setLoading('등록중입니다.');
          this.$axios.post(url, params).then(res=>{
            this.clearLoading();
            if(res.status===200) {
              this.routeGa('', '소스 파일 등록 문의', '등록완료', '응답');
              this.toast('문의 내용이 등록되었습니다.');
              if(this.isLogin) {
                this.$router.replace('/mypage/my_template_inquiry')
              } else {
                this.$router.replace('/home')
              }
            }
          }).catch(err => {
            this.clearLoading();
            if (err.response.data.message) {
              this.toast(err.response.data.message);
            } else if (err.response.data.detail) {
              this.toast(err.response.data.detail);
            } else {
              this.toast('오류가 발생하였습니다. 관리자에게 문의주세요.');
            }
          });
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .banner
    background-color $subcolor50
    padding 50px 16px
    text-align center
</style>
