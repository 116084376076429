<template>
  <div>
    <!-- 인풋 -->
    <div class="flex-align
                full-width
                position-relative">
      <textarea class="textarea"
                :class="{'error-st': errorMsg}"
                :placeholder="placeholder"
                :maxlength="maxLength"
                @keyup="e=>onKeyup(e.target.value)"
                v-model="inputValue"></textarea>

    </div>

  </div>
</template>

<script>

  export default {
    name: 'LpTextArea',
    props: {
      value: {
        type: String
      },
      disabled: {
        type: Boolean,
        required: false
      },
      maxLength: {
        type: Number,
        required: 20000
      },
      placeholder: {
        type: String,
        default: ''
      },
      errorMsg: {
        type: String,
        required: false,
        default: ''
      },
      validate: {
        type: Object,
        default: function() {
          return {
            valid_required: false,
            error_msg: '',
            max_length:100,
            min_length:0,
            alpha_s: true,
            alpha_g: true,
            special: true,
            num: true
          }
        }
      },
      rows: {
        type: Number,
        required: false,
        default: 7
      }
    },

    created() {
      if(this.value) {
        this.inputValue = this.value;
      }
      if(this.validate) {
        this.valid = this.validate;
      }
    },
    watch: {
      value(n) {
        this.inputValue = n;
      }
    },
    data() {
      return {
        inputValue: ''
      };
    },

    computed: {
    },

    methods: {
      onKeyup(value) {
        this.$emit('update:errorMsg', '');
        this.inputValue = value;
        this.$emit('update:value', this.inputValue);
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .error-st
    border 1px solid $error !important
</style>
