import { render, staticRenderFns } from "./LpRadio.vue?vue&type=template&id=42d035f7&scoped=true&"
import script from "./LpRadio.vue?vue&type=script&lang=js&"
export * from "./LpRadio.vue?vue&type=script&lang=js&"
import style0 from "./LpRadio.vue?vue&type=style&index=0&id=42d035f7&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42d035f7",
  null
  
)

export default component.exports