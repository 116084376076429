<template>
  <div>
    <div class="flex wrap">
      <input-img-uploader v-for="(file, idx) in files"
        :key="'file-'+fileKeys[idx]" :useLabel="false"
        :value="file" :files="files" :index="idx"
        @update:value="addImg" @delImg="delImg"></input-img-uploader>
    </div>
  </div>
</template>

<script>

import ImageUploaderMixin from "../../mixins/ImageUploaderMixin";
import InputImgUploader from "./InputImgUploader";

export default {
  name: "InputImgUploaderArray",
  props: {
    isRequire: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Array]
    },
    limit: {
      type: Number,
      default: 5
    },
    isImgDeletable: {
      type: Boolean,
      default: true
    }
  },
  mixins: [
    ImageUploaderMixin
  ],
  components: {
    InputImgUploader
  },
  created() {
    if(this.value.length===0) {
      this.files = [''];
    } else {
      if (this.value.length < this.limit) {
        if (this.files.indexOf('') === -1) {
          this.files = ['', ...this.value];
        } else {
          this.files = this.value;
        }
      } else {
        this.files = this.value;
      }
    }
    this.initKeys();
  },
  data() {
    return {
      files: [],
      fileKeys: []
    }
  },
  methods: {
    initKeys() {
      this.fileKeys = [];
      for(let i=0; i<this.limit; i++) {
        this.fileKeys.push(this.uuidv4());
      }
    },
    addImg(value) {
      if (this.files.length <= this.limit) {
        // multiple images
        if (Array.isArray(value)) {
          if (this.files.length === 1) {
            this.files.pop();
          } else {
            this.files.shift();
          }
          this.files = this.files.concat(value);
          this.files = ['', ...this.files];
          if (this.files.length > this.limit) {
            this.files.shift();
            this.files = this.files.slice(0, this.limit);
          }

        }
        // single image
        else {
          if (this.files.length === 1) {
            this.files.pop();
          } else {
            this.files.shift();
          }
          this.files.push(value)
          this.files = ['', ...this.files]
          if (this.files.length > this.limit) {
            this.files.shift();
            this.files = this.files.slice(0, this.limit);
          }
        }
        this.initKeys();
        this.$emit('update:value', this.files);
      }
    },
    delImg(index) {
      this.files.remove(index);
      if(this.files.length === 0) {
        this.files.push("");
      } else if (this.files.length > 0) {
        if (this.files.indexOf('') === -1) {
          this.files = ['', ...this.files];
        }
      }
      this.initKeys();
      this.$emit('update:value', this.files);
    }
  },
  computed: {

  },
  watch: {
    value(n) {
      if(n.length===0) {
        this.files = [''];
      } else {
        if (n.length < this.limit) {
          if (n.indexOf('') === -1) {
            this.files = ['', ...n];
          } else {
            this.files = n;
          }
        } else {
          this.files = n;
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
